import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  Box, 
  Button,
  Link,
  Container, 
  Typography, 
  Grid
} from '@mui/material';

import ProductImage from "../../../assets/images/products.png";

import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import PublicIcon from '@mui/icons-material/Public';
import LinkIcon from '@mui/icons-material/Link';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import styles from "../../../global.scss";
import "../styles.scss";

const AccountsReceivablePage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  // stepper


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    // ...theme.applyStyles('dark', {
    //   backgroundColor: theme.palette.grey[800],
    // }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  // ...theme.applyStyles('dark', {
  //   backgroundColor: theme.palette.grey[700],
  // }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      <strong>
        {icons[String(props.icon)]}
      </strong>
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};



  const steps = [
    'Generate a payment link for your US customers', 
    'Send the link via email, SMS, WhatsApp, or embed it in your invoice', 
    'Customer clicks the link and completes the payment',
    "Customer funds are transferred directly to your bank account",
    "Invoice is automatically marked as paid in your system",
  ];



  return (
    <Container maxWidth="xl">
      <Box className="products">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Products</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={ProductImage}
              alt='product'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box className="products__section" 
          id={"web-app"}>
          <Typography variant='h4' mb={"60px"}>Accounts receivable</Typography>
          {/* <Typography variant='h5' textAlign={"left"} mb={"20px"}>
            Streamline Your Global Accounts Payables
          </Typography> */}
          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }} 
            mb={"20px"}>
            Our Accounts Receivable solution empowers SMBs to collect payments quickly and easily from customers worldwide, track invoices, and optimize cash flow across borders.
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "40px" }}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<PublicIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Global Payment Acceptance</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Receive payments in over 38 currencies from customers in 180 countries, expanding your business reach globally.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LinkIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Easy Payment Links</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Send payment links directly to customers via email or mobile message (SMS or WhatsApp). Customers can pay directly from their bank account to yours, eliminating the need checks and reducing payment delays.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<DatasetLinkedIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Embedded Invoice Links</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Include payment links within your PDF invoices and emails, allowing customers to pay with a single click, regardless of their location.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<CurrencyExchangeIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Multi-Currency Invoice Tracking</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Keep track of all customer invoices in various currencies, including overdue payments, in one centralized system.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<AccessAlarmIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Automated Reminders</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Send automated reminders to customers worldwide for overdue invoices, improving your collection rate.
									</Typography>
								</Box>
							</Box>
						</Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<PriceCheckIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Global Cash Flow Optimization</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
										Gain insights into your receivables across different currencies and countries, optimizing your cash flow with detailed reporting and analytics.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Box
            mt={"150px"}
            className="flexCenterCenterColumn">
            <Typography variant='h5' textAlign={"left"}>
              Benefits for your business
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <BoltTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Speed</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
											Faster payment collection, improving cash flow
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <ManageAccountsIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Manage</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>             
											Better visibility into outstanding invoices and payment patterns
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="home__products-features flexCenterCenterColumn" gap="15px"
                sx={{
                  padding: "50px",
                  backgroundColor: "white",
                  border: "solid 1px #f1f1f1"
                }}>
                  <TravelExploreIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
                  <Box className="flexCenterCenterColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
                    <Typography variant='h6' textAlign={"center"}>Global expansion</Typography>
                    <Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
											Improved customer experience with easy, localized payment options
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={"100px"}
            className="flexCenterCenterColumn"
            gap={"40px"}>
            <Typography variant='h5' textAlign={"left"} mb={"10px"}>
              How it works
            </Typography>
            <Stepper alternativeLabel activeStep={7} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      variant='caption'>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
           	mt={"120px"}
            className="flexCenterCenterColumn"
            gap={"20px"}>
            <Typography variant='h5' textAlign={"left"} mb={"20px"}>
							Ready to go global with your accounts receivables?
            </Typography>
            <Typography variant='body1' 
              sx={{ color: "grey", marginBottom: "20px" }} 
              textAlign={"left"}>
              Sign up now and start collecting payments in the USA and from around the world!
            </Typography>
            <Link 
              href={process.env.REACT_APP_CORE_WEB_URL}
              target="_blank" rel="noopener noreferrer"
              >
              <Button variant='outlined'>Get started</Button>
            </Link>
          </Box>
        </Box>
      </Box>
		</Container>
  )
}

export default AccountsReceivablePage;